import React from "react";
import {Query} from "react-apollo";
import gql from "graphql-tag";


const POLL_INTERVAL = 10000;

export default function AnonymousOrderVersionMonitor(props) {
  const {
    anonymousCartId,
    version: currentVersion,
    onChange,
  } = props;

  let versionVariables = {
    anonymousCartId: anonymousCartId
  }

  return (
    <Query query={VERSION} variables={versionVariables} pollInterval={POLL_INTERVAL} fetchPolicy="no-cache">
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;

        if (data.anonymousCheckoutVersion && data.anonymousCheckoutVersion.version !== currentVersion) {
          onChange(data.anonymousCheckoutVersion.version);
        }
        return null;
      }}
    </Query>
  );
}

const VERSION = gql`
  query versionInfo($anonymousCartId: ID!) {
    anonymousCheckoutVersion(anonymousCartId: $anonymousCartId) {
      version
    }
  }
`
