import React from "react";
import i18n from "../../i18n";

export default function January2025PromoInstructions() {

  // German
  if (i18n.language === "de") {
    return (
      <div>
        <p><strong>Erreichen Sie Ihre Wellness-Ziele!</strong> Abonnieren Sie <strong>zwei 6er-Kartons</strong> von Hallstein, und wir fügen Ihrer ersten Lieferung einen kostenlosen 6er-Karton hinzu. Es ist unsere Absicht, Ihre Wellness-Ziele in diesem Jahr zu unterstützen. Angebot gültig vom 21. Januar bis 31. Januar. <em>Sie müssen keine Promo-Codes eingeben, die Sendung wird automatisch erstellt und erscheint im Bereich „Lieferungen“ auf Ihrer Kontoseite.</em></p>
      </div>
    )
  }

  // English
  return (
    <div>
      <p><strong>Unlock Your Wellness Goals!</strong> Subscribe to <strong>two 6-bottle cases</strong> of Hallstein, we’ll add a complimentary 6-bottle case to your first delivery. It’s our way of supporting your wellness goals this year. Offer valid between January 21st until January 31st. <em>You do not have to enter any promo codes, the shipment will be automatically created and will show up in the "Deliveries" section on your account page.</em></p>
    </div>
  );

}